var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.medicion_adet,"search":_vm.search,"footer-props":{
        'items-per-page-text': '',
      },"locale":"es-CO","sort-by":['fecha_med_timestamp'],"sort-desc":[true],"must-sort":[true]},scopedSlots:_vm._u([{key:"item.fecha_med_timestamp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.fecha_med_timestamp).toLocaleDateString("es-CO"))+" ")]}},{key:"item.fecha_prog_timestamp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.fecha_prog_timestamp).toLocaleDateString("es-CO"))+" ")]}},{key:"item.resultado",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.resultado),"dark":""}},[_vm._v(" "+_vm._s(item.resultado)+" ")])]}}])})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" CERRAR ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }