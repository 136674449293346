<template>
  <div>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="medicion_adet"
        :search="search"
        :footer-props="{
          'items-per-page-text': '',
        }"
        locale="es-CO"
        :sort-by="['fecha_med_timestamp']"
        :sort-desc="[true]"
        :must-sort="[true]"
      >
        <template v-slot:item.fecha_med_timestamp="{ item }">
          {{ new Date(item.fecha_med_timestamp).toLocaleDateString("es-CO") }}
        </template>
        <template v-slot:item.fecha_prog_timestamp="{ item }">
          {{ new Date(item.fecha_prog_timestamp).toLocaleDateString("es-CO") }}
        </template>
        <template v-slot:item.resultado="{ item }">
          <v-chip :color="getColor(item.resultado)" dark>
            {{ item.resultado }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          CERRAR
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  computed: {
    medicion_adet: function() {
      return this.$store.state.medicion_adet;
    },
  },
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      search: "",
      headers: [
        { text: "UN", value: "un" },
        { text: "SERVICIO", value: "servicio" },
        { text: "SENTIDO", value: "sentido" },
        { text: "PERIODO", value: "periodo" },
        { text: "FECHA PROG", value: "fecha_prog_timestamp" },
        { text: "FECHA MED", value: "fecha_med_timestamp" },
        { text: "COD PARADA", value: "cod_parada" },
        { text: "COD SIMT", value: "cod_simt" },
        { text: "COMUNA", value: "comuna" },
        { text: "NOM PARADA", value: "nom_parada" },
        { text: "MEDICION", value: "num_med" },
        { text: "ESPERA REAL", value: "espera_real" },
        { text: "ESPERA ACEP", value: "espera_acep" },
        { text: "RESULTADO", value: "resultado" },
        { text: "TER/TEA", value: "ter_tea" },
        { text: "ESTADO", value: "estado_desc" },
      ],
    };
  },
  watch: {
    search: function(val) {
      this.search = val.toUpperCase();
    },
  },
  methods: {
    getColor(resultado) {
      if (resultado == "POSITIVO") return "green";
      else if (resultado == "NEGATIVO") return "red";
      else return "white";
    },
  },
};
</script>
